define('hk-purple3-blueprints/components/blueprint-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    title: 'Section Title',
    subtitle: 'Section Subtitle'
  });
});